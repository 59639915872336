import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import ProcessSections from '../utils/processSections'
import CategoryList from './CategoryList'

const EducationGrid = styled.div`
  .title {
    h1 {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    p.author {
      font-size: 66%;
    }
  }
`
const getDate = dateStr => new Date(dateStr).toLocaleString()

export default function SingleEducationItemPage({ data }) {
  const { education } = data

  let dateProps = {}

  if (education._updatedAt) {
    dateProps = {
      time: getDate(education._updatedAt),
      update: true,
    }
  } else {
    dateProps = {
      time: getDate(education._createdAt),
      update: false,
    }
  }

  const dateStr = `${dateProps.update ? 'Updated' : 'Created'} at ${
    dateProps.time
  }`

  return (
    <>
      <EducationGrid className="content-width">
        <div className="title">
          <h1>{education.title}</h1>
          <p className="author">
            <em>
              Written by {education.author}. {dateStr}
            </em>
          </p>
        </div>
        <ProcessSections sections={education} />
        <CategoryList categories={education.categories} baseUrl="/education" />
      </EducationGrid>
    </>
  )
}

export const query = graphql`
  query($slug: String) {
    education: sanityEducation(slug: { current: { eq: $slug } }) {
      id
      title
      headline
      content: _rawContent(resolveReferences: { maxDepth: 25 })
      author
      _updatedAt
      _createdAt
      categories {
        id
        name
        color {
          hex
        }
        invert
      }
    }
  }
`
