import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const ListStyle = styled.ul`
  margin: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  list-style: none;
  padding: 0;

  li {
    font-size: 80%;
    padding: 5px 8px;
    border-radius: 5px;
    margin-right: 5px;

    a {
      text-decoration: none;
    }
  }
`

const createList = ({ categories, baseUrl }) => (
  <ListStyle>
    {categories.map(category => (
      <li
        style={{
          backgroundColor: category.color.hex,
        }}
        key={category.id}
      >
        <Link
          style={{ color: category.invert ? '#ffffff' : '' }}
          to={`${baseUrl}?cat=${category.name}`}
        >
          {category.name}
        </Link>
      </li>
    ))}
    <li>Labels: </li>
  </ListStyle>
)

export default createList
